import React, { useState, useEffect } from 'react';
import InstagramPost from './InstagramPost.jsx';
import * as qf from '../../misc/qf';

import './InstagramFeed.css';

let isScriptLoaded = false;

const InstagramFeedOnly = ({ accountAttachedTo, href }) => {
  const [feed, setFeed] = useState([]);
  const [page, setPage] = useState(1);
  const [postFeedUrl, setPostFeedUrl] = useState('');
  const [nextPageUrl, setNextPageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPost, setIsLastPost] = useState(false);
  const [name, setName] = useState('');
  const [postMetaArr, setPostMetaArr] = useState({});
  const [hideFeed, setHideFeed] = useState(true);
  let localState = {
    isLoading: false,
    isLastPost: false,
  };
  
  useEffect( () => {
    const el = document.getElementById("instagram");
    const config = { attributes: true };
    const feedLoadFunc = () => {
      setHideFeed(false);
      // Load initial posts
      qf.getJSON(href)
        .then( res => {
          setPostFeedUrl(href);
          setFeed(res.posts);
          setPage(res.next_page);
          setName(res.name);
          if(res.last_posts) setIsLastPost(res.last_posts);
          if(window.instgrm) newEmbeds();
        })
        .catch( err => console.error(err) );

      // Load Instagram Embed Script
      if(isScriptLoaded) return;
      const script = document.createElement('script');
      script.src = "https://www.instagram.com/embed.js";
      script.onLoad = () => window.instgrm.Embeds.process();
  
      let body = document.querySelector('body');
      body.appendChild(script);
      isScriptLoaded = true;
  
      setupScrollWatch();
    }
    if (el.classList.contains('active')) {
      feedLoadFunc();
    } else {
      const cb = (mutationList, observer) => {
        for (let mutation of mutationList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class' && el.classList.contains('active')) {
            feedLoadFunc();
          }
        }
      }
      const observer = new MutationObserver(cb);
      observer.observe(el, config);
      () => observer.disconnect();
    }
  }, []);

  useEffect( () => {
    if(!hideFeed) {
      const loadingSpinnerEl = document.getElementById('loadingSpinnerInstagram');
      const loadPostsObserver = new IntersectionObserver( (entries, observer) => entries.forEach(entry => entry.isIntersecting && getNextPage(localState)) );
      if(isLastPost) {
        loadPostsObserver.unobserve(loadingSpinnerEl);
        loadingSpinnerEl.classList.add("u-hide");
      } else {
        loadPostsObserver.observe(loadingSpinnerEl)
        return() => loadPostsObserver.unobserve(loadingSpinnerEl);
      }
    }
  }, [nextPageUrl, isLastPost, hideFeed]);

  useEffect( () => {
    const url = `${postFeedUrl}&page=${page}`;
    setNextPageUrl(url);
  }, [page])

  useEffect( () => {
    localState.isLastPost = isLastPost;
  }, [isLastPost]);

  const scrollDetectPosts = () => {
    const postsOnPage = document.querySelectorAll('#instagramFeedContainer > .instagramPost');

    let showhide = {...postMetaArr};
    postsOnPage.forEach( (el, idx) => {
      const firstChild = el.firstChild;

      if(firstChild.tagName === "IFRAME") {
        const childHeight = firstChild.clientHeight;
        const childOffsetTop = firstChild.offsetTop;
        const scrollHeight = window.scrollY;

        const distanceToHide = 4000; // distance from viewable area to hide post
        const elHeight = childOffsetTop + childHeight; // bottom position of post element
        const liveHeight = window.innerHeight + scrollHeight + distanceToHide; // lower bound to hide posts
        const turnOffTop = scrollHeight - distanceToHide; // upper bound to hide posts
        showhide = {...showhide, [idx]: ((elHeight - turnOffTop) < 0) || (childOffsetTop - liveHeight > 0) };
      }
    });
    setPostMetaArr(showhide);
  };

  // debounce scroll to prevent multiple firings while scrolling
  const debounce = (func, delay) => {
    clearTimeout(func._timeout);
    func._timeout = setTimeout( () => func(), delay);
  }

  const setupScrollWatch = () => {
    window.addEventListener('scroll', () => debounce(scrollDetectPosts, 500));
  };

  function getNextPage(localState) {
    if(localState.isLoading) return;
    localState.isLoading = true;
    setIsLoading(true);
    qf.getJSON(nextPageUrl)
      .then( res => {
        setPage(res.next_page);
        if(res.last_posts) setIsLastPost(res.last_posts);
        if(res.posts.length > 0) {
          setFeed(feed.concat(res.posts));
          newEmbeds();
        }
      })
      .catch( err => {
        console.error(err);
      })
      .finally( () => {
        setIsLoading(false);
        localState.isLoading = false;
      });
  };

  const newEmbeds = () => {
    window.instgrm.Embeds.process(); // embed.js code that process function
  };

  if(feed === '' || hideFeed) return (
    <div className="w-100 text-center py-5">
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading Posts&hellip;
    </div>
  );

  return (
    <section id="instagramFeedContainer" className="text-start">
      { feed !== undefined && feed.map( (postInfo, idx) => <InstagramPost info={postInfo} name={name} order={idx} showHide={postMetaArr[idx]} key={postInfo.id} /> ) }
      <div id="loadingSpinnerInstagram" className="w-100 text-center py-5">
        {
          isLoading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading Posts&hellip;
            </>
          ) : <></>
        }
      </div>
    </section>
  )
}

export default InstagramFeedOnly;
