import csrfToken from '../csrfToken.js';

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function parseJSON(response) {
  return response.json();
}

export function deleteJSON(url) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': token,
      'X-HTTP-Method-Override': 'DELETE',
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
  }).then(checkStatus).then(parseJSON);
}

export function del(url) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': token,
      'X-HTTP-Method-Override': 'DELETE',
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
  }).then(checkStatus);
}

export function getJSON(url) {
  if (!url) {
    throw new Error('invalid url');
  }
  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, max-age=0',
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
  }).then(checkStatus).then(parseJSON);
}

export function get(url) {
  if (!url) {
    throw new Error('invalid url');
  }
  return fetch(url, {
    headers: {
      'Cache-Control': 'no-cache, max-age=0',
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
  }).then(checkStatus);
}

export function patchFormData(url, data) {
  if (!url) {
    throw new Error('invalid url');
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken(),
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
    body: data,
  }).then(checkStatus).then(parseJSON);
}

export function patchJSON(url, params) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  if (!params) {
    params = {};
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
      'X-HTTP-Method-Override': 'PATCH',
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  }).then(checkStatus).then(parseJSON);
}

export function post(url, params = {}) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  if (!params) {
    params = {};
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  }).then(checkStatus);
}

export function postFormData(url, data) {
  if (!url) {
    throw new Error('invalid url');
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken(),
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
    body: data,
  }).then(checkStatus).then(parseJSON);
}

export function postJSON(url, params = {}) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  if (!params) {
    params = {};
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  }).then(checkStatus).then(parseJSON);
}

export function putJSON(url, params) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  if (!params) {
    params = {};
  }
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
      'X-HTTP-Method-Override': 'PUT',
      'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  }).then(checkStatus).then(parseJSON);
}

export function getWithAuth(url, params, auth) {
  if (!url) {
    throw new Error('invalid url');
  }
  const token = csrfToken();
  if (!params) {
    params = {};
  }
  let authStr = '';
  if (!authorization) {
    auth = ''
  } else if (authorization.type !== undefined && authorization.type.trim() !== '') {
    authStr = toString(authorization.type) + toString(authorization.token);
  } else {
    authStr = toString(authorization.token);
  }
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
      'X-HTTP-Method-Override': 'PUT',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': authStr,
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  }).then(checkStatus)
}
