function load () {
  try {
    return JSON.parse(document.getElementById('preloaded_json').text);
  } catch (err) {
    return {};
  }
}

const data = load();
if (window) {
  window.PRELOADED_JSON = data;
}
export default data;
