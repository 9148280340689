import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded';

import InstagramFeedOnly from '../../src/socialMedia/instagram/InstagramFeedOnly.jsx';

const { team_instagram, instagram_url } = preloaded;

const el = document.getElementById('instagramContainer');

if(el && !!team_instagram) ReactDOM.render( <InstagramFeedOnly accountAttachedTo={`team_id=${team_instagram.team_id}`} href={instagram_url} />, el);
